//folder.js
import request from '@/utils/request'

//查询文件夹及文件列表
export function qeryFolderList(page, limit, suffix) {
  return request({
    url: '/GetFileList',
    method: 'post',
    data: {
      page: page,
      limit: limit,
      suffix: suffix
    }
  })
}

//查询文件个数
export function getFileCount(suffix) {
  return request({
    url: '/getCount?suffix=' + suffix,
    method: 'get',
  })
}

//获得文件缩略图
export function getThumbnail(md5) {
  return request({
    url: '/GetThumbnail?md5=' + md5,
    method: 'get',

  })
}



//===================文件=====================

//上传文件-后端数据库
export function addFile(data) {
  return request({
    url: '/upload',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

//删除文件
export function deleteFile(fileId) {
  return request({
    url: 'deleteFile',
    method: 'post',
    data: {
      md5: fileId
    }
  })
}


//移动文件夹
export function getDataDistinguish(TargetFile) {

  return request({
    url: '/GetDistinguish',
    method: 'post',
    data: {
      DrawPath: TargetFile.split("\\")[0],
      DrawName: TargetFile.split("\\")[1].split(".")[0]
    }

  })
}

// 转换文件
export function convertFile(fileId) {
  return request({
    url: '/Pdf2Image',
    method: 'post',
    data: {
      md5: fileId
    }
  })
}

// 获得图纸路径根目录
export function getRootPath() {
  return request({
    url: '/getRootPath',
    method: 'post',
    data: {

    }
  })
}

// 获取图纸路径下的文件
export function getDrawImage(path) {
  return request({
    url: '/getDrawImage',
    method: 'post',
    data: {
      Path: path
    }
  })
}

// 执行识别图纸
export function executeDistinguish(path, Again,modle) {
  return request({
    url: '/Distinguish',
    method: 'post',
    data: {
      Path: path,
      Again: Again,
      Pattern:modle
    }
  })
}
// 执行识别图纸2
export function executeDistinguishNew(path, Again) {
  return request({
    url: '/DistinguishNew',
    method: 'post',
    data: {
      Path: path,
      Again: Again
    }
  })
}

export function OriginalImage(path) {
  return request({
    url: '/OriginalImage?path=' + path,
    method: 'get',

  })
}

export function getProgress() {
  return request({
    url: '/getProgress',
    method: 'get',
  })

}
export function seteditData(data11, path) {
  return request({
    url: '/editData',
    method: 'post',

    data: {
      path: path.split("\\")[0],
      name: path.split("\\")[1].split(".")[0],
      data: JSON.stringify(data11)
    }
  })

}

export function getDatapktask(pktask) {
  return request({
    url: '/getDatapktask',
    method: 'post',
    data: { 
      pktask:  parseInt(pktask)
    }
  })
}

export function getRecognitionImageList({ size = 5, current = 1, type, requestBody  = {} }) {
  return request({
    url: '/asyncTask/list',
    method: 'post',
    headers:{
      "Content-Type": "application/json; charset=UTF-8;"
    },
    params: {
      size,
      current,
      type
    },
    data: requestBody
  });
}